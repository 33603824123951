@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* --------- IDS --------- */

#Home {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-bottom: auto;
}
#HomeButton {
  display: flex;
  align-items: center;
  background-color: #edecea;
}
#AboutMe {
  height: 100dvh;
  width: 100dvw;
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
#ContactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 10%; */
}
#ChessPage {
  padding-top: 7%;
}

/* --------------------------------------- GENERAL ---------------------------------------------- */
a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vw;
  color: #0a0a0a;
  text-decoration: none;
}

.Button {
  margin-right: 20px;
}

.ButtonLink {
  color: #889981;
  font-size: 75px !important;
}

.Centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomeIcon {
  color: #889981;
  padding-left: 11.5%;
  padding-top: 9%;
}

.HomeButton {
  height: 45px;
  width: 45px;
  border: transparent 2px solid;
  border-radius: 50%;
}

.HomeButton:hover {
  border: #889981 2px solid;
  cursor: pointer;
}

.HomeIconPosition {
  padding-left: 2%;
  padding-top: 0.5%;
}

h1 {
  color: #889981;
  font-weight: 500;
  font-size: 5em;
  margin-top: 4dvh;
  margin-bottom: 4dvh;
  /* font-family: Courier New, monospace; */
  /* font-size: 4.5vw; */
  /* padding-right: 10px; */
}

h2 {
  /* font-size: 4.5vw; */
  color: black;
  font-size: 3em;
}
h2.Typography {
  font-family: Arial, Helvetica, sans-serif;
}

h3 {
  font-size: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  color: #889981;
  font-weight: lighter;
  margin-top: 0;
  margin-bottom: 2.5vh;
}

h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2vw;
  margin-top: 0;
  margin-bottom: 0;
  color: #0a0a0a;
}

p {
  font-size: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  color: #0a0a0a;
  font-weight: lighter;
  text-align: justify;
}

.links {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10%;
}

.Link {
  color: whitesmoke;
  text-decoration: none;
}

.Link:hover {
  color: black;
}

.MuiButton-root {
  border-radius: 8px;
}

.MuiTypography-root {
  font-family: Courier New, monospace;
  /* font-family: Georgia, "Times New Roman", Times, serif; */
}

strong {
  font-weight: bold;
  color: aqua;
}
.StrongTitle {
  color: black;
}

.Typography {
  font-family: Courier New, monospace;
  /* font-weight: 100; */
}

/* .TypographyH1 {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Arial, Helvetica, sans-serif;
} */

#Nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 10vh;
}

.Nav {
  display: flex;
}

.NavButton {
  border: 2px solid #889981;
  border-radius: 500px;
  display: flex;
  /* height: 4vh; */
  align-self: center;
  /* justify-self: center; */
  margin-left: 3vw;
  /* margin-right: 3vw; */
  text-align: center;
}
.NavButton:hover {
  border: 2px solid transparent;
  background-color: #889981;
  /* color: #fafafa; */
}

.NavButton .NavButtonText:hover {
  color: #edecea;
}
.NavButtonText {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: 2vh;
  font-weight: lighter;
  /* margin: 10%; */
  /* padding: 10% 15% 10% 15%; */
  padding-top: 0.75vh;
  padding-bottom: 0.75vh;
  padding-left: 1vw;
  padding-right: 1vw;
  color: #889981;
  /* position: relative; */
}

/* --------------------------------------- HOME PAGE -------------------------------------------- */

.BlueBox {
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 9vh;
}

.HomePage {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  height: 100dvh;
  width: 80dvw;
}

.HomeIconPosition {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 99;
}

.HomeNavButton {
  border: 2px solid #889981;
  border-radius: 500px;
  display: flex;
  height: 7vh;
  width: 12vw;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: #889981;
  text-align: center;
}
.HomeNavButton:hover {
  border: 2px solid transparent;
  background-color: #889981;
  cursor: pointer;
}

.HomeNavButton .HomeNavButtonText:hover {
  color: #edecea;
  cursor: pointer;
}
.HomeNavButtonText {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: 2.5vh;
  font-weight: lighter;
  padding-top: 3vh;
  padding-bottom: 3vh;
  color: #fafafa;
}
/* ---------------------------------------- INFO PAGE ------------------------------------------- */

.Info-section {
  height: 100vh;
  display: flex;
  align-items: center;
}

.Info {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  height: 80%;
  width: 90%;
  justify-content: space-around;
}

.InfoRow {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
}

img {
  /* margin-top: 2em; */
  /* margin-bottom: 2em; */
  width: 100%;
  border-radius: 50%;
}

.LeftSide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  margin-right: 2%;
  /* margin-bottom: 2em; */
  /* flex: 0 1 auto; */
}

.RightSide {
  display: flex;
  width: fit-content;
  min-width: 50%;
  /* flex: 1 0 100%; */
  flex-direction: column;
  justify-content: space-around;
}

.TypographyH1Info {
  padding-left: 5px;
  color: aqua;
}
/* ---------------------------------------- CHESS BOARD ----------------------------------------- */
.ChessSection {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.BoardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 640px;
  height: 640px;
  border: 20px solid rgb(8, 212, 244);
  justify-content: center;
  align-items: center;
}
.Board {
  background-color: antiquewhite;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 5px;
}
.GreySquare {
  background-color: grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  height: 80px;
  width: 80px;
}
.WhiteSquare {
  background-color: antiquewhite;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  height: 80px;
  width: 80px;
}
.FillerPiece {
  font-family: "copperplate";
  font-size: xx-large;
  color: #0a0a0a;
}
.FillerPiece:hover {
  cursor: pointer;
}

/* ---------------------------------------- CONTACT PAGE ---------------------------------------- */
.HiddenForPhone {
  display: none;
}
.BiggerIcon {
  font-size: 50px !important;
  padding-left: 4px;
  padding-top: 4px;
  rotate: 10deg;
  color: #889981;
}

.Circle {
  border: 4px solid #889981;
  border-radius: 50%;
  height: 58px;
  width: 58px;
}

.Contact-Block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20vh;
}

.ContactBubbleLink {
  margin-right: 0;
}

.ContactColumn {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.Contact-Row {
  width: 90dvw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100dvh;
}

.contact {
  display: flex;
  align-items: center;
}

.IconBubble {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 10%;
  height: 80px;
  width: 80px;
  margin-right: 5vw;
}

.Right-Side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.LinkedIn {
  color: #889981;
  font-size: 85px !important;
}

.LinkedinBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 10%;
  height: 80px;
  width: 80px;
  margin-right: 5vw;
}

.Left-Side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 75dvh;
}

.Right-Row {
  display: flex;
  flex-direction: column;
}

.BlueBoxContact {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 9vh;
}

.LittleRow {
  display: flex;
  justify-content: flex-end;
}

.PhoneInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TypographyBolder {
  font-size: 5.5vw;
  padding-right: 0;
  margin-top: 1vh;
  font-family: Arial, Helvetica, sans-serif;
  color: aqua;
}
/* ----------------------------------------- RESUME PAGE ---------------------------------------- */

.MyResume {
  padding-bottom: 10px;
}

/* --------------------------------------- Float n parry ---------------------------------------- */

#PlayArena {
  /* position: relative; */
  /* left: 50%;
  right: 50%; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: #fafafa;
  height: 500px;
  border-top: 2vh solid aqua;
  border-bottom: 2vh solid greenyellow; */
}

#Canvas {
  background-color: #fafafa;
  position: relative;
}

#Character {
  height: 50px;
  width: 50px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.StartRow {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.Start_Button:hover {
  cursor: pointer;
}

.Start_Button {
  display: flex;
  height: 30px;
  width: 60px;
  border: Aqua 2px solid;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 479px) {
  /* start of phone styles */
  /* .BlueBox {
    background-color: aqua;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 7vh;
  } */

  /* .BlueBoxInfo {
    background-color: aqua;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 7vh;
  } */

  /* .BlueBoxContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: aqua;
    height: 7vh;
  } */

  .InfoRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .LeftSide {
    width: 30%;
    height: 200px;
  }
  a {
    font-size: 3vw;
  }
  path {
    height: 100%;
    width: 100%;
  }
  #ContactSection {
    display: flex;
    flex-direction: column;
  }
  p.Typography {
    font-size: 3vw;
  }
  h1.Typography {
    font-size: 2em;
    /* display: none; */
  }
  .HiddenForPhone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5%;
  }
  .Left-Side {
    display: none;
  }
  .Right-Side {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: fit-content;
    padding-left: 15%;
    padding-right: 20%;
  }
  .LinkedinBlock {
    margin-right: 5vw;
    height: 70px;
  }

  .IconBubble {
    margin-right: 5vw;
    height: 70px;
  }

  .TypographyBolder {
    font-size: 10vw;
  }

  .HomeNavButton {
    border: 2px solid #889981;
    border-radius: 500px;
    display: flex;
    height: 6vh;
    width: 20vw;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .HomeNavButtonText {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 3vw;
    font-weight: lighter;
    padding-top: 0.75vh;
    padding-bottom: 0.75vh;
    padding-left: 1vw;
    padding-right: 1vw;
    color: #edecea;
  }

  h1 {
    font-size: 8vw;
  }

  h3 {
    font-size: 5vw;
    padding-bottom: 2vh;
  }

  h4 {
    font-size: 3vw;
  }

  p {
    font-size: 4vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
