body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edecea;
  /* height: 2000px; */
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media screen and (max-width: 479px) {
  body {
    width: 40;
  }
} */
